import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Map from '../components/homepage/Map'
import InputField from '../components/form/InputField'
import StillHaveQuestions from '../components/StillHaveQuestions'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import useFormState from '../components/form/hooks/useFormState'
import FormWrapper from '../components/form/FormWrapper'
import PropTypes from 'prop-types'
import ContactAnimation from '../components/ContactAnimation'
import isEmail from '../components/form/validators/isEmail'
import isPhone from '../components/form/validators/isPhone'
import { processGatsbyImageDataMock } from '../common/gatsbyImageData'

const ContactCard = ({ image, name, title, email, phone }) => {
  return (
    <div className="grid grid-cols-3 bg-white border border-gray-300 gap-3 p-5 rounded-xl max-w-[500px] w-full mx-auto">
      <div className="my-auto col-span-1 w-full border border-gray-300 aspect-square rounded-full flex justify-center items-center overflow-hidden">
        <GatsbyImage image={getImage(image)} alt={name} className="object-fill w-full h-full" />
      </div>
      <div className="col-span-2 flex flex-col w-full gap-1 my-auto">
        <p className="font-bold uppercase">{name}</p>
        <p>{title}</p>
        {email && <a href={'mailto:' + email}>{email}</a>}
        {phone && <a href={'tel:+1' + phone.replace(/[^0-9]/, '')}>{phone}</a>}
      </div>
    </div>
  )
}
ContactCard.propTypes = {
  image: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
}

const ContactPageForm = () => {
  const [form, , updateForm] = useFormState({
    name: '',
    phone: '',
    email: '',
    message: '',
  })

  const { details } = useStaticQuery(graphql`
    query OurStaff {
      details: allStrapiTeamMember(
        filter: { email: { nin: ["", null] } }
        sort: { fields: priority, order: DESC }
      ) {
        nodes {
          id
          email
          name
          title
          image {
            gatsbyImageDataMock
          }
        }
      }
    }
  `)
  details.nodes.forEach(contact => {
    processGatsbyImageDataMock(contact.image)
  })

  return (
    <Layout className={'mx-auto'} breadcrumbs={false}>
      <Seo
        description="Looking for Pre-Owned Luxury watches or used designer jewelry? Let us help. Talk to our industry experts."
        title="CONTACT US"
      />

      <h1 className="text-red-700 text-center text-4xl pt-12">CONTACT FORM</h1>

      <div className="w-10/12 max-w-[1366px] mx-auto flex flex-col lg:flex-row">
        {/* CONTACT US PAGE  
         SVG Animated Icons */}
        <div className="w-full lg:w-1/2 mx-auto max-w-[500px]">
          <ContactAnimation />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col justify-center">
          <FormWrapper
            form={form}
            updateForm={updateForm}
            name={'Contact Us'}
            action={'/thank-you-contact-form/'}
            className="w-full space-y-3"
          >
            <p className="my-5">
              Please fill out the form below and we will get back to you as soon as possible.
            </p>
            <InputField
              name={'name'}
              label={'Name'}
              form={form}
              onChange={updateForm}
              required={true}
            />
            <InputField
              name={'phone'}
              label={'Telephone'}
              form={form}
              onChange={updateForm}
              validator={isPhone}
              required={true}
            />
            <InputField
              name={'email'}
              label={'E-Mail'}
              type="email"
              validator={isEmail}
              form={form}
              onChange={updateForm}
              required={true}
            />
            <InputField
              name={'message'}
              type="textarea"
              rows={4}
              label={'Message'}
              form={form}
              onChange={updateForm}
              required={true}
            />
            <button className=" my-4 max-w-max mx-auto lg:mx-0 rounded-md bg-blue-600 text-white font-bold py-3 px-5 hover:bg-blue-800">
              SEND MESSAGE
            </button>
          </FormWrapper>
        </div>
      </div>
      <hr className="mt-5 mx-auto w-10/12" />
      <h2 className="underline-title text-2xl my-10">Our Staff</h2>
      <div className=" grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-3 w-10/12 max-w-[1366px] mx-auto justify-center items-center">
        {details.nodes.map(contact => (
          <ContactCard
            key={contact.id}
            image={contact.image}
            name={contact.name}
            title={contact.title}
            email={contact.email}
          />
        ))}
      </div>

      <StillHaveQuestions />

      <Map />
    </Layout>
  )
}

export default ContactPageForm
